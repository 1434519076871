<fieldset>
  <!-- Cause container-->
  <div *ngIf="causesGroup" [formGroup]="causesGroup">
    <ul>
      <li *ngFor="let item of checkboxes">
        <label class="custom-checkbox" [class.checked]="hasChecked('cause', item.id)">
          <input type="checkbox" [value]="item.id"
                 (change)="onChangeCheckbox($event, causesGroup)"/><span>{{item.name}}</span>
        </label>
      </li>
      <!--li(*ngIf="hasDescriptionGroup")
      label.custom-checkbox([class.checked]="isCheckedOther.causes")
          input(type="checkbox",
              (change)="onChangeOther($event)")
          span Egyéb
      -->
    </ul>
    <!--label.custom-text-input(*ngIf="hasDescriptionGroup && isCheckedOther.causes", [formGroup]="descriptionGroup")
    span Magyarul
    input(type="text", formControlName="description_hu")
    -->
    <!--label.custom-text-input(*ngIf="hasDescriptionGroup && isCheckedOther.causes", [formGroup]="descriptionGroup")
    span Angolul
    input(type="text", formControlName="description_en")
    -->
  </div>
  <!-- Effect container-->
  <div *ngIf="effectsGroup" [formGroup]="effectsGroup">
    <ul>
      <li *ngFor="let item of checkboxes; let i = index;">
        <label class="custom-checkbox" [class.checked]="hasChecked('effect', item.id)">
          <input type="checkbox" [value]="item.id" (change)="onChangeCheckbox($event, effectsGroup)"/><span>{{item.name}}</span>
        </label>
      </li>
      <!--li(*ngIf="hasDescriptionGroup")
      label.custom-checkbox([class.checked]="isCheckedOther.effects")
          input(type="checkbox",
              (change)="onChangeOther($event)")
          span Egyéb
      -->
    </ul>
    <!--label.custom-text-input(*ngIf="hasDescriptionGroup && isCheckedOther.effects", [formGroup]="descriptionGroup")
    span Magyarul
    input(type="text", formControlName="description_hu")
    -->
    <!--label.custom-text-input(*ngIf="hasDescriptionGroup && isCheckedOther.effects", [formGroup]="descriptionGroup")
    span Angolul
    input(type="text", formControlName="description_en")
    -->
  </div>
  <!-- Replacement container-->
  <div *ngIf="replacementsGroup" [formGroup]="replacementsGroup">
    <ul formArrayName="checks">
      <li *ngFor="let item of checkboxes; let i = index;">
        <label class="custom-checkbox" [class.checked]="hasCheckedMulti(i, item.id)">
          <input type="checkbox" [value]="item.id"
                 (change)="onChangeMultiCheckBox($event)"/><span>{{item.name}}</span>
        </label>
      </li>
      <!--li(*ngIf="hasDescriptionGroup")
      label.custom-checkbox([class.checked]="isCheckedOther.replacements")
          input(type="checkbox",
              (change)="onChangeOther($event)")
          span Egyéb
      -->
    </ul>
    <!--label.custom-text-input(*ngIf="hasDescriptionGroup && isCheckedOther.replacements", [formGroup]="descriptionGroup")
    span Magyarul
    input(type="text", formControlName="description_hu")
    -->
    <!--label.custom-text-input(*ngIf="hasDescriptionGroup && isCheckedOther.replacements", [formGroup]="descriptionGroup")
    span Angolul
    input(type="text", formControlName="description_en")
    -->
    <label class="search-label"><strong>Alternatív járat hozzáadása</strong>
      <search-editor class="search-input" (onShowResultList)="isShowOverlay($event)" [type]="'accordion'"
                     [form-group]="replacementsGroup" [control-mame]="'transits'"></search-editor>
    </label>
    <ul class="routes" *ngIf="transitList" formArrayName="transits">
      <li *ngFor="let item of transitList; let i = index;" [class]="item.className"><a href="#"
                                                                                       [title]="item.details.id"
                                                                                       (click)="onRemoveRouteItem($event, i)"><span
        class="icon"></span><span class="text" [style.color]="item.styles.color"
                                  [style.background]="item.styles.background">{{item.details.name}}</span></a><i
        class="fa fa-times-circle-o"></i></li>
    </ul>
  </div>
</fieldset>
