<h5 [class.bigger-size]="isBothDirectionSelected && direction === 'inbound'">
    Az {{direction | directionPipe}} irányhoz tartozó elérhető útvonalak:
</h5>
<div class="search-trips-container">
    <input placeholder="GTFS Dátum/Shape ID/Célállomás..."
           name="search-input"
           type="text"
           [(ngModel)]="searchInputValue"
           (keyup)="searchOtherTrips()">
    <i class="fa fa-plus"></i>
    <div class="trips-container"
         [class.smaller-size]="isBothDirectionSelected"
         [class.aligned]="isBothDirectionSelected && direction === 'inbound'">
        <ul>
            <li *ngFor="let trip of tripsBySearch">
                <a href="#"
                   (click)="selectTrip($event, trip)">
                    {{trip.last_feed.created_at}}:&nbsp;{{trip.shape_id}} - {{trip.headsign}}
                </a>
                <span *ngIf="trip.id === selectedRouteDetails.outbound_trip_id ||
                             trip.id === selectedRouteDetails.inbound_trip_id">
                    (Alapértelmezett útvonal)
                </span>
            </li>
        </ul>
    </div>
</div>