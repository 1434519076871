<ul class="routes">
    <li *ngFor="let route of routes; let i=index;" [class]="route.className">
        <a href="#" [title]="route.details.id" (click)="onClickRouteItem($event, route, i)">
            <span class="icon"></span>
            <span class="text"
                  [style.color]="route.styles.color"
                  [style.background]="route.styles.background">{{route.details.name}}</span>
        </a>
    </li>
</ul>
<fieldset *ngIf="selectedRouteDetails" [formGroup]="currentRouteFormGroup">
    <div class="container">
        <remove-button class="remove" [currentId]="selectedRouteDetails.id" [routes]="routes"
                       (removedItem)="removedCurrentItem($event)"></remove-button>
        <ul>
            <li *ngIf="selectedRouteDetails.direction !== 'loop'">
                <label class="custom-radio" [class.checked]="hasChecked('outbound')">
                    <input type="radio" name="direction" formControlName="direction" [value]="'outbound'"/><span>Alapértelm.&nbsp;irány </span>
                </label>
            </li>
            <li *ngIf="selectedRouteDetails.direction !== 'loop'">
                <label class="custom-radio" [class.checked]="hasChecked('inbound')">
                    <input type="radio" name="direction" formControlName="direction" [value]="'inbound'"/><span>Ellenkező&nbsp;irány</span>
                </label>
            </li>
        </ul>
        <ul class="loop">
            <li *ngIf="selectedRouteDetails.direction === 'loop'">
                <label class="custom-radio" [class.checked]="hasChecked('loop')">
                    <input type="radio" name="direction" formControlName="direction"
                           [value]="'loop'"/><span>Hurok&nbsp;irány </span>
                </label>
            </li>
            <li *ngIf="selectedRouteDetails.direction !== 'loop'">
                <label class="custom-radio" [class.checked]="hasChecked('both')">
                    <input type="radio" name="direction" formControlName="direction"
                           [value]="'both'"/><span>Mindkét&nbsp;irány</span>
                </label>
            </li>
        </ul>
        <a class="choose" href="" title="" (click)="chooseAnotherRoute($event)">
            Másik útvonal kiválasztása
        </a>
        <popup-routes *ngIf="isRoutesPopupVisible && routes"
                      (closePopupEvent)="popupClosed()"
                      [selectedRouteDetails]="selectedRouteDetails"
                      (stopsByDirectionUpdated)="handleStopsByDirectionUpdated($event)"
                      (tripIdsUpdated)="handleTripIdsUpdated($event)"></popup-routes>
    </div>

    <stop-list [options]="selectedRouteDetails.stops"
               [previousSelectedStops]="previousStops"
               [isAddAllStops]="isAddAllStops"
               [isAnnouncement]="isAnnouncement"
               (onClickedItem)="onClickedItem($event)"></stop-list>
</fieldset>
