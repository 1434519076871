<header class="container">
  <h1 id="bkk-logo"><a class="sprite" href=""></a></h1>
  <div id="user-container"><strong>{{user.name}}</strong><span class="sprite"></span><a id="logout" href=""
                                                                                        (click)="onClickLogout($event)">Alkalmazás
    bezárása</a></div>
</header>
<hr/>
<div class="container"><a id="add-disorder" [routerLink]="['/disorder-editor']" routerLinkActive="active">Új zavar
  hozzáadása</a></div>
<router-outlet></router-outlet>
<footer>
  <p>Hibabejelentés: <a href="http://bkkhelpdesk.kozlek.local/" target="_blank">BKÜ Informatikai Szolgáltatások - Help Desk</a> / +36 30 774 1111 / <a href="mailto:HelpDesk@bkk.hu">HelpDesk@bkk.hu</a></p>
</footer>
