<form class="container" id="editor-form" [formGroup]="editorForm" (submit)="onSubmit(editorForm)"
      novalidate="novalidate">
  <div id="form-header">
    <search-editor class="search-input" (onShowResultList)="isShowOverlay($event)" [form-group]="editorForm"
                   [control-mame]="'added_routes'"></search-editor>
    <date-time id="date-time"
               [exist-model]="existFormData"
               [isClosingPopupOpen]="false"
               (onStartTimeChanged)="startTypeChanged($event)"></date-time>
    <ul id="radios-btn-container">
      <li>
        <label class="custom-radio" [class.checked]="hasCheckedRadio('operational')">
          <input type="radio" name="type" value="operational" formControlName="type" (change)="disorderTypeChanged()"/><span>Operatív</span>
        </label>
      </li>
      <li>
        <label class="custom-radio" [class.checked]="hasCheckedRadio('planned')">
          <input type="radio" name="type" value="planned" formControlName="type" (change)="disorderTypeChanged()"/><span>Tervezett</span>
        </label>
      </li>
      <li>
        <label class="custom-radio" [class.checked]="hasCheckedRadio('announcement')">
          <input type="radio" name="type" value="announcement" formControlName="type" (change)="disorderTypeChanged()"/><span>Közlemény</span>
        </label>
      </li>
    </ul>
  </div>
  <section [class.overlay]="isOverlay">
    <disorder-section id="disorder-section"
                      [exist-model]="existFormData"
                      [isAnnouncement]="isAnnouncement"
                      (onSelectedRoute)="setSelectedRoute($event)"></disorder-section>
    <accordion id="accordion"
               [exist-model]="existFormData"
               [isAnnouncement]="isAnnouncement"
               (showOverlay)="isShowOverlay($event)"
               [currentRoute]="selectedRoute"></accordion>
    <disorder-confirm id="disorder-confirm" [(exist-model)]="existFormData"
                      (onClickSaveBtnEvent)="onClickedSaveBtn()" (onClickRemoveBtnEvent)="onClickedRemoveBtn()"
                      [id]="disorderId"></disorder-confirm>
  </section>
</form>
