<table>
  <thead #thead>
  <tr>
    <th><a href="#" title="Azonosítóra rendezés" (click)="onClickOrderBtn($event, 'id')">No.<i class="fa"
                                                                                               [order-icon]="'id'"></i></a>
    </th>
    <th>Vonalak</th>
    <th><a href="#" title="Kezdetre rendezés" (click)="onClickOrderBtn($event, 'start_at')">Kezdet<i class="fa"
                                                                                                     [order-icon]="'start_at'"></i></a>
    </th>
    <th><a href="#" title="Végre rendezés" (click)="onClickOrderBtn($event, 'end_at')">Vég<i class="fa"
                                                                                             [order-icon]="'end_at'"></i></a>
    </th>
    <th><a href="#" title="Dátumra rendezés" (click)="onClickOrderBtn($event, 'updated_at')">Módosítva<i class="fa"
                                                                                                         [order-icon]="'updated_at'"></i></a>
    </th>
    <th><a href="#" title="Létrehozóra rendezés" (click)="onClickOrderBtn($event, 'username')">Létrehozó<i class="fa"
                                                                                                           [order-icon]="'username'"></i></a>
    </th>
    <th><a href="#" title="Típusra rendezés" (click)="onClickOrderBtn($event, 'type')">Típus<i class="fa"
                                                                                               [order-icon]="'type'"></i></a>
    </th>
    <th>Műveletek</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let item of disorders; let i = index;" [class]="item.status">
    <td>{{item.id}}</td>
    <td class="routes-column">
      <ul class="routes">
        <li *ngFor="let line of item.routes" [class]="line.type"><span class="icon"></span><span class="text"
                                                                                                      [ngStyle]="getLineStyle(line)">{{line.name}}</span><i>{{line.id}}</i>
        </li>
      </ul>
    </td>
    <td><span *ngIf="item.active_time !== null">{{item.active_time | dateTimeValue: 'start_date'}}&nbsp;</span><strong
      *ngIf="item.active_time !== null">{{item.active_time | dateTimeValue: 'start_time'}}</strong></td>
    <td><span *ngIf="item.active_time !== null">{{item.active_time | dateTimeValue: 'end_date'}}&nbsp;    </span><strong
      *ngIf="item.active_time !== null">{{item.active_time | dateTimeValue: 'end_time'}}</strong></td>
    <td>{{item.updated_at | date:'y-MM-dd HH:mm'}}</td>
    <td><span *ngIf="item.user !== null">{{item.user.name}}</span></td>
    <td class="type">{{item.type | changeTypeValue }}</td>
    <td [class.active]="isActiveClass(item.id)">
      <disorder-item-action class="item-action" (on-remove-item)="onRemoveItem($event, i)" [disorder]="item" [index]="i"
                            [defaultTop]="vDim"></disorder-item-action>
    </td>
  </tr>
  </tbody>
</table>
<div class="archieve-list-container" [class.show]="isShowArchieveList" [style.top.px]="listContainerTop"
     [innerHtml]="logs"></div>
