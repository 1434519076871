<section id="popup-close">
  <h3>Zavar lezárása</h3>
  <date-time [isClosingPopupOpen]="true" [exist-model]="disorder"></date-time>
  <label>
    <input type="checkbox" [checked]="isImmediateCloseActive" (change)="isImmediateCloseActive = !isImmediateCloseActive" />
    <span>Azonnali lezárás</span>
  </label>
  <hr/>
  <form [formGroup]="shareForm" (submit)="onSubmit()" enctype="multipart/form-data" novalidate="novalidate">
    <fieldset>
      <legend>Automatikus Facebook bejegyzés</legend>
      <ul>
        <li class="image-container">
          <i *ngIf="!hasDefaultImage" (click)="clearImage()" class="fa fa-times"></i>
          <img [src]="fileSrc"/>
          <label>Új kép feltöltése
            <input #imageInput type="file" (change)="onChangeFileInput($event)"/>
          </label>
        </li>
        <li>
          <textarea formControlName="message"></textarea>
        </li>
      </ul>
      <strong [class.first-share]="!hasLastShare"><span *ngIf="hasLastShare">Utoljára megosztva: {{dateConfigs[0]}} <b>{{dateConfigs[1]}}</b>&nbsp;-&nbsp;{{userObject.name}}</span>
        <input class="fb-share" type="submit" *ngIf="!hasSharing" formControlName="button"/><b class="fb-share"
                                                                                               *ngIf="hasSharing"><i
          class="fa fa-cog fa-spin fa-3x fa-fw"></i></b></strong>
    </fieldset>
  </form>
  <hr/>
  <div class="button-group">
    <a class="button cancel" href="" (click)="onClickCancel($event)">Mégse</a>
    <a class="button apply" href="" (click)="resetDisorderStatus($event)">Publikálás visszavonása</a>
    <a class="button apply" href="" (click)="onClickClose($event)">Lezárás</a>
  </div>
</section>
