<input type="text" placeholder="Járatszám/ForTe kód..." [(ngModel)]="searchedRoute" (ngModelChange)="search($event)"/>
<i class="fa fa-plus"></i>
<div *ngIf="showResultList">
    <ul class="component-popup">
        <li *ngFor="let route of routesList">
            <a href="#" [title]="route.description"
               (click)="onClickRouteItem($event, route.id)">
              {{route.id}}:&nbsp;<span class="boldi">{{route.name}}</span>&nbsp;{{route.description}}
            </a>
        </li>
        <li>
          <a href="#"
             *ngIf="fullResultList.length > routesList.length"
            (click)="showAll($event)">
            30 napnál régebben érvényes útvonalak mutatása
          </a>
        </li>
        <li>
          <a href="#"
             *ngIf="loading && !routesList.length">
            Betöltés...
          </a>
        </li>
    </ul>
</div>
