<ul *ngIf="hideFirstList">
  <li *ngFor="let item of checkboxItems">
    <label class="custom-checkbox" [class.checked]="item.checked">
      <input type="checkbox" [name]="item.disorderType" [checked]="item.checked"
             (change)="onChangeCheckboxItem($event, item)"/><span>{{item.title}}</span>
    </label>
  </li>
</ul>
<ul>
  <li id="date-time-intervallum-item" [formGroup]="dateFormGroup">
    <label class="custom-checkbox" [class.checked]="!dateFormGroup.disabled">
      <input type="checkbox" (change)="onChangeCheck($event)"/><span>Egyedi időintervallum</span>
    </label>
    <input class="date" type="text"
           formControlName="start_date"
           (show-calendar)="showCalendar($event, true)"
           [group-name]="'start'"
           [placeholder]="dateTimePlaceHolders.startDate"
           [textMask]="{mask: dateMaskFormatter}"/>
    <input class="time" type="text"
           formControlName="start_time"
           [placeholder]="dateTimePlaceHolders.startTime"
           [textMask]="{mask: timeMaskFormatter}"/>
    <span>-</span>
    <input class="date" type="text"
           formControlName="end_date"
           (show-calendar)="showCalendar($event, true)"
           [group-name]="'end'"
           [placeholder]="dateTimePlaceHolders.endDate"
           [textMask]="{mask: dateMaskFormatter}"/>
    <input class="time" type="text"
           formControlName="end_time"
           [placeholder]="dateTimePlaceHolders.endTime"
           [textMask]="{mask: timeMaskFormatter}"/>
    <span>-ig</span>
    <calendar class="calendar" *ngIf="isShowCalendar" [ngStyle]="dimension" [enableTimeBlock]="enableTime"
              (onSelectedDate)="onSelectedDate($event)" (onChangedTime)="onSettedTime($event)"
              (onClickedCloseBtn)="onHideCalendar()"></calendar>
  </li>
  <li id="search-item">
    <label>
      <input type="search" [(ngModel)]="searchValue" name="query" (blur)="onChangeSearch($event)"
             placeholder="Zavar keresése..."/><span class="sprite search"></span>
    </label>
    <button type="submit">Mehet</button>
  </li>
</ul>
