<a class="fa fa-trash" href="#" title="Törlés" (click)="onClickDelete($event, disorder)"
   [class.hide]="!gate.can('i-delete-drafts')">

</a>
<a class="fa fa-times-circle-o" href="#" title="Lezárás"
   (click)="onClickDeactive($event, disorder)"
   [class.hide]="!gate.can('i-close-changes')">

</a>
<a class="fa fa-check-circle-o" href="#" title="Publikálás" (click)="onClickApply($event, disorder)"
   [class.hide]="!gate.can('i-approve-changes')">

</a>
<a class="fa fa-facebook-official"
   href="#"
   title="Megosztás"
   (click)="onClickShare($event, disorder)"
   [facebook-item]="disorder"
   [permissions]="gate.getPermissions()"
   [class.hide]="!gate.can('i-share-changes')"
   [is-shared]="shared"
   [is-closed-shared]="closedShared">

</a>
<a class="fa fa-pencil" href="#"
   title="Szerkesztés"
   (click)="onClickEdit($event, disorder.id)"
   [class.hide]="!gate.can('i-update-changes')">

</a>
<a class="fa fa-file-text-o" href="#" title="Archivált lista" [class.show]="disorder.logs_count &gt; 0"
   (click)="onClickArchieve($event, disorder.id)"></a>
