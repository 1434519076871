<div>
  <nav><a class="fa fa-chevron-left" href="#" title="előző" [class.hide-prev]="!hasPreviousNav"
          (click)="onClickNavBtn($event, true)"></a><strong>{{defaultDateText | changeMonthString}}</strong><a
    class="fa fa-chevron-right" href="#" title="következő" (click)="onClickNavBtn($event, false)"> </a></nav>
  <ul>
    <li class="day-names" *ngFor="let name of dayNames"><strong>{{name}} </strong></li>
    <li *ngFor="let item of calendarConfig"><a href="#" title="{{item.day}}" [class]="item.className"
                                               (click)="onClickDateItem($event, item.date, item.className)">{{item.day}}</a>
    </li>
  </ul>
  <div class="selection" *ngIf="enableTimeBlock">
    <label>Óra:
      <select (change)="onChangeTime($event, true)" [(ngModel)]="selectedHour">
        <option *ngFor="let hour of hours" [value]="hour">{{hour}}</option>
      </select>
    </label>
    <label>Perc:
      <select (change)="onChangeTime($event, false)" [(ngModel)]="selectedMinute">
        <option *ngFor="let minute of minutes" [value]="minute">{{minute}}</option>
      </select>
    </label>
  </div>
  <span><a href="#" title="Bezárás" (click)="onClickNowBtn($event)">Ma</a><a href="#" title="Bezárás"
                                                                             (click)="onClickCloseBtn($event)">Bezárás</a></span>
</div>
