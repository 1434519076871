<ul [formGroup]="editorForm">
  <li *ngFor="let item of accordions"
      [class]="item.class">
    <a href=""
       (click)="clickItem($event, item)">
      <span>{{item.title}}</span>
      <strong>{{item.selectedNames}}
      </strong>
      <i class="add-time-btn"
         *ngIf="item.isOpen && item.type === 'date-time'">
        <strong>Új időpont</strong>
        <i class="fa fa-plus"></i></i><i [class]="(item.isOpen) ? 'fa fa-chevron-up' : 'fa fa-chevron-down'"></i></a>
    <checkbox-container class="checkbox-container content"
                        *ngIf="item.type === 'checkbox' && item.datas && item.api === 'causes'"
                        [causes]="item" [service]="item.service"
                        [changed-formgroup]="item.formGroup"
                        [checkboxes]="item.datas"
                        [apiKey]="item.api"
                        [class.open]="item.isOpen"
                        [is-open]="item.isOpen"
                        [class.has-textarea]="item.hasTextarea"
                        (onDisplayOverlay)="isShowOverlay($event)"></checkbox-container>
    <checkbox-container class="checkbox-container content"
                        *ngIf="item.type === 'checkbox' && item.datas && item.api === 'effects'"
                        [effects]="item" [service]="item.service"
                        [changed-formgroup]="item.formGroup"
                        [checkboxes]="item.datas"
                        [apiKey]="item.api"
                        [class.open]="item.isOpen"
                        [is-open]="item.isOpen"
                        [class.has-textarea]="item.hasTextarea"
                        (onDisplayOverlay)="isShowOverlay($event)"></checkbox-container>
    <checkbox-container class="checkbox-container content"
                        *ngIf="item.type === 'checkbox' && item.datas && item.api === 'replacements'"
                        [replacements]="item" [service]="item.service"
                        [changed-formgroup]="item.formGroup"
                        [transit-list]="item.transitList"
                        [checkboxes]="item.datas"
                        [apiKey]="item.api"
                        [class.open]="item.isOpen"
                        [is-open]="item.isOpen"
                        [class.has-textarea]="item.hasTextarea"
                        (remove-transit)="removeTransitEvent($event)"
                        (select-multi-checkbox)="selectMultiCheckbox($event)"
                        (onDisplayOverlay)="isShowOverlay($event)"></checkbox-container>
    <label class="custom-textarea-label content"
           *ngIf="item.type === 'description'"
           [class.open]="item.isOpen">
      <div class="title">Magyar</div>
      <app-tinymce class="custom-textarea"
                   *ngIf="item.type === 'description'"
                   formControlName="comment_hu"></app-tinymce>
    </label>
    <label class="custom-textarea-label content"
           *ngIf="item.type === 'description'"
           [class.open]="item.isOpen">
      <div class="title">Angol</div>
      <app-tinymce class="custom-textarea"
                   *ngIf="item.type === 'description'"
                   formControlName="comment_en"></app-tinymce>
    </label>
    <label class="custom-textarea-label content"
           *ngIf="item.type === 'details'"
           [class.open]="item.isOpen">
      <div class="title">Magyar</div>
      <app-tinymce class="custom-textarea"
                   *ngIf="item.type === 'details'"
                   formControlName="details_hu"></app-tinymce>
    </label>
    <label class="custom-textarea-label content" *ngIf="item.type === 'details'" [class.open]="item.isOpen">
      <div class="title">Angol</div>
      <app-tinymce class="custom-textarea" *ngIf="item.type === 'details'" formControlName="details_en"></app-tinymce>
    </label>
    <date-time-container class="date-time-container content"
                         *ngIf="item.type === 'date-time'"
                         [itemConfig]="item"
                         [class.open]="item.isOpen"></date-time-container>
  </li>
</ul>
