<popup-delete class="popup-content" *ngIf="isContainName('delete')" (onClickCancelEvent)="onClickedCancel()"
              [style.z-index]="popupContentsName.indexOf('delete') + 10"></popup-delete>
<popup-close class="popup-content" *ngIf="isContainName('close')" (onClickCancelEvent)="onClickedCancel()"
             [style.z-index]="popupContentsName.indexOf('close') + 10"></popup-close>
<popup-share class="popup-content" *ngIf="isContainName('share')" (onClickCancelEvent)="onClickedCancel()"
             [style.z-index]="popupContentsName.indexOf('share') + 10"></popup-share>
<popup-apply class="popup-content" id="apply" *ngIf="isContainName('apply')" (onClickCancelEvent)="onClickedCancel()"
             [style.z-index]="popupContentsName.indexOf('apply') + 10"></popup-apply>
<popup-warning class="popup-content" *ngIf="isContainName('warning')" (onClickCancelEvent)="onClickedCancel()"
               [style.z-index]="popupContentsName.indexOf('warning') + 10"></popup-warning>
<div class="popup-content" id="popup-loader" *ngIf="isShowLoader" [style.z-index]="popupContentsName.length + 10"><i
  class="fa fa-circle-o-notch fa-spin"></i></div>
