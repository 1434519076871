<div class="container" *ngIf="dateTimeFormGroup">
  <fieldset *ngFor="let item of dateTimeFormGroup.controls; let i = index;">
    <div class="header">
      <legend>További&nbsp;időpont&nbsp;{{i + 1}}.</legend>
      <button class="remove" type="button" (click)="onClickRemoveBtn(i)">Töröl<i class="fa fa-close"></i></button>
    </div>
    <div class="body">
      <div [formGroup]="item.controls.start">
        <select formControlName="select">
          <option *ngFor="let startSelect of getSelectList(i, 'start')" [value]="startSelect.value">
            {{startSelect.title}}
          </option>
        </select>
        <input class="date" type="text"
               formControlName="date"
               [placeholder]="currentDate | date: 'y-MM-dd'"
               [group-name]="'start'"
               [textMask]="{mask: dateMaskFormatter}"
               (show-calendar)="showCalendar($event, true, i)"
               (keyup)="onChangeInputByType('start', item.controls.start, i)"/>
        <input class="time" type="text"
               formControlName="time"
               [placeholder]="currentDate | date: 'HH:mm'"
               [textMask]="{mask: timeMaskFormatter}"
               (keyup)="onChangeInputByType('start', item.controls.start, i)"/>
      </div>
      <span>-</span>
      <div [formGroup]="item.controls.end">
        <select formControlName="select">
          <option *ngFor="let endSelect of getSelectList(i, 'end')" [value]="endSelect.value">{{endSelect.title}}
          </option>
        </select>
        <input class="date" type="text"
               formControlName="date"
               [placeholder]="currentDate | date: 'y-MM-dd'"
               [group-name]="'end'"
               [textMask]="{mask: dateMaskFormatter}"
               (show-calendar)="showCalendar($event, true, i)"
               (keyup)="onChangeInputByType('end', item.controls.end, i)"/>
        <input class="time" type="text"
               formControlName="time"
               [placeholder]="currentDate | date: 'HH:mm'"
               [textMask]="{mask: timeMaskFormatter}"
               (keyup)="onChangeInputByType('end', item.controls.end, i)"/>
      </div>
    </div>
  </fieldset>
  <calendar class="calendar" *ngIf="isShowCalendar" [ngStyle]="dimension" [enableTimeBlock]="true"
            [previousDates]="previousDates" (onSelectedDate)="onSelectedDate($event)"
            (onChangedTime)="onSettedTime($event)" (onClickedCloseBtn)="onHideCalendar()"></calendar>
</div>
