<section id="search-trips-popup">
    <i class="fa fa-times" (click)="closePopup($event)"></i>

    <search-trips *ngIf="!isBothDirectionSelected"
                  [direction]="direction"
                  [isBothDirectionSelected]="isBothDirectionSelected"
                  [selectedRouteDetails]="selectedRouteDetails"
                  (bothDirectionStopsUpdated)="handleBothDirectionStopsUpdated($event)"
                  (stopsByDirectionUpdated)="handleStopsByDirectionUpdated($event)"
                  (tripIdsUpdated)="handleTripIdsUpdated($event)"></search-trips>

    <ng-container *ngIf="isBothDirectionSelected">
        <search-trips [direction]="'outbound'"
                      [isBothDirectionSelected]="isBothDirectionSelected"
                      [selectedRouteDetails]="selectedRouteDetails"
                      (bothDirectionStopsUpdated)="handleBothDirectionStopsUpdated($event)"
                      (stopsByDirectionUpdated)="handleStopsByDirectionUpdated($event)"
                      (tripIdsUpdated)="handleTripIdsUpdated($event)"></search-trips>

        <search-trips [direction]="'inbound'"
                      [isBothDirectionSelected]="isBothDirectionSelected"
                      [selectedRouteDetails]="selectedRouteDetails"
                      (bothDirectionStopsUpdated)="handleBothDirectionStopsUpdated($event)"
                      (stopsByDirectionUpdated)="handleStopsByDirectionUpdated($event)"
                      (tripIdsUpdated)="handleTripIdsUpdated($event)"></search-trips>
    </ng-container>

    <div class="error">{{ errorMessage }}</div>

    <button type="button" (click)="saveSelectedTrip($event)">Mentés</button>
</section>

<div class="popup-wrapper"></div>
