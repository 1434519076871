<div class="block" [formGroup]="editorForm">
  <ng-container *ngIf="!isClosingPopupOpen">
    <div formGroupName="start">
      <select formControlName="select" (change)="startTypeChanged()">
        <option *ngFor="let item of beginTimeTypeOptions" [value]="item.value">{{item.title}}</option>
      </select>
      <input class="date" type="text"
             formControlName="date"
             [textMask]="{mask: dateMaskFormatter}"
             [placeholder]="currentDate | date: 'y-MM-dd'"
             [group-name]="'start'" [select-value]="startSelectValue"
             (show-calendar)="showCalendar($event, true)"
             (keyup)="onChangeInputByType('start')"/>
      <input class="time" type="text"
             formControlName="time"
             [textMask]="{mask: timeMaskFormatter}"
             [placeholder]="currentDate | date: 'HH:mm'"
             (keyup)="onChangeInputByType('start')"/>
    </div>
    <span>-</span>
  </ng-container>

  <div formGroupName="end">
    <span *ngIf="isClosingPopupOpen">Záró időpont: </span>
    <select formControlName="select">
      <option *ngFor="let item of endTimeTypeOptions" [value]="item.value">{{item.title}}</option>
    </select>
    <input class="date" type="text"
           formControlName="date"
           [textMask]="{mask: dateMaskFormatter}"
           [placeholder]="currentDate | date: 'y-MM-dd'"
           [group-name]="'end'"
           [select-value]="endSelectValue"
           (show-calendar)="showCalendar($event, true)"
           (keyup)="onChangeInputByType('end')"/>
    <input class="time" type="text"
           formControlName="time"
           [textMask]="{mask: timeMaskFormatter}"
           [placeholder]="currentDate | date: 'HH:mm'"
           (keyup)="onChangeInputByType('end')"/>
  </div>

  <calendar class="calendar" *ngIf="isShowCalendar"
            [ngStyle]="dimension"
            [enableTimeBlock]="hasTime"
            [isDisableNowTime]="'true'"
            [reset-calendar]="resetCalendar"
            (onSelectedDate)="onSelectedDate($event)"
            (onChangedTime)="onSettedTime($event)"
            (onClickedCloseBtn)="onHideCalendar()"></calendar>
</div>
