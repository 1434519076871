<header>
  <h3>Előnézeti kép</h3>
</header>
<section>
  <iframe [src]="url" width="100%" height="100%"></iframe>
</section>
<footer>
  <button type="button" (click)="onClickCancel($event)">Vissza</button>
  <button id="reset-btn" type="button" (click)="onClickReset($event)" *ngIf="enableResetButton">Visszaállítás
    piszkozatra
  </button>
  <button type="button" (click)="onClickApply($event)" *ngIf="showApplyBtn">{{applyBtnText}}</button>
</footer>
