<div class="content">
  <fieldset>
    <legend>Kapcsolódó változás(ok)</legend>
    <div class="search-input">
      <label>
        <input type="text" placeholder="Járatszám/ForTe kód..." (keyup)="onChangeSearchField($event)"
               (blur)="onBlurInput($event)"/><i class="fa fa-plus"></i>
        <div class="related-disorder" *ngIf="showResultList">
          <ul class="component-popup">
            <li *ngFor="let disorder of selectableRelatedDisorderList"><a href="#" [title]="disorder.description"
                                                                          (click)="onClickDisorderItem($event, disorder)">{{disorder.id}}:&nbsp;{{disorder.cause?.name}}</a>
            </li>
          </ul>
        </div>
      </label>
    </div>
    <div class="touched-routes" *ngFor="let item of relatedRoutes; let i = index;">
      <div class="wrapper">
        <ul class="routes">
          <li *ngFor="let route of item.routes"><span class="icon"></span>
            <span class="text"
                  [class.prl-10]="route.type ==='chairlift'"
                  [ngStyle]="getRouteStyle(route)">
              {{route.name}}
            </span>
          </li>
        </ul>
        <a class="fa fa-times-circle" href="#" title="Törlés" (click)="onRemoveRelateItem($event, i)"></a>
      </div>
      <table>
        <tbody>
        <tr>
          <td>Azonosító:</td>
          <td>{{item.id}}</td>
        </tr>
        <tr>
          <td>Kezdés ideje:</td>
          <td>{{item.active_time.start_date}}</td>
        </tr>
        <tr>
          <td>Zavar típus:</td>
          <td class="status">{{item.effectDes}}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </fieldset>
</div>
<div class="content">
  <div class="buttons">
    <ul>
      <li>
        <button type="button" (click)="onClickRemoveBtn()">Elvetés</button>
      </li>
      <li>
        <button type="button" (click)="onClickSaveBtn()">Mentés</button>
      </li>
    </ul>
    <ul class="share-buttons">
      <li>
        <button class="share"  type="button" (click)="share('push')" *ngIf="status === 'published' && gate.can('i-share-changes')" [disabled]="isChangeForm === false">PUSH küldés</button>
      </li>
      <li>
        <button class="share"  type="button" (click)="share('mail')" *ngIf="status === 'published' && gate.can('i-share-changes')" [disabled]="isChangeForm === false">Levélküldés</button>
      </li>
    </ul>
    <button id="send-apply" type="submit" [disabled]="isChangeForm === false">{{submitBtnTile}}</button>
  </div>
</div>
