<ul>
  <li><a href="#" title="Első" (click)="onClickPaginationNav($event, 1)" [class.disabled]="disableFirstBtn">1 </a></li>
  <li><a class="fa fa-angle-left" href="#" (click)="onClickPaginationNav($event, 'prev')"
         [class.disabled]="disablePrevBtn"></a></li>
  <li><a class="fa fa-angle-double-left" href="#" (click)="onClickPaginationNav($event, 'prev-double')"
         [class.disabled]="disablePrevDoubleBtn"></a></li>
  <li *ngFor="let num of numbers" [class.hide]="num &gt; endNumber"><a href="#"
                                                                       (click)="onClickPaginationNumber($event, num)"
                                                                       [class.selected]="selectedNumber === num">{{num}}</a>
  </li>
  <li><a class="fa fa-angle-double-right" href="#" (click)="onClickPaginationNav($event, 'next-double')"
         [class.disabled]="disableNextDoubleBtn"></a></li>
  <li><a class="fa fa-angle-right" href="#" (click)="onClickPaginationNav($event, 'next')"
         [class.disabled]="disableNextBtn"></a></li>
  <li><a href="#" title="Utolsó" (click)="onClickPaginationNav($event, endNumber)" [class.disabled]="disableLastBtn">{{endNumber}}</a>
  </li>
</ul>
